import React from "react";

const Services = () => {
  return (
    <section className="pb-12 pt-20  lg:pb-[90px] lg:pt-[120px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
              <span className="mb-2 block text-lg font-semibold text-primary">
                Our Services
              </span>
              <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark  sm:text-4xl md:text-[40px]">
                What We Offer
              </h2>
              <p className="text-base text-body-color ">
                Explore what we offer and find solutions that cater to your
                needs. From top-notch services to a diverse range of products,
                we have everything you need to enhance your space.
              </p>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap">
          <ServiceCard
            title="Interior design"
            details="Our interior design services bring creativity, functionality, and sophistication to your space. From concept to completion, we'll turn your vision into a stunning reality."
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 100 100"
              >
                {/* <!-- Walls --> */}
                <rect x="10" y="20" width="80" height="60" fill="#f0f0f0" />

                {/* <!-- Furniture - Table --> */}
                <rect x="20" y="60" width="60" height="20" fill="#a9a9a9" />
                <line
                  x1="50"
                  y1="60"
                  x2="50"
                  y2="80"
                  stroke="#000"
                  stroke-width="2"
                />

                {/* <!-- Chair --> */}
                <rect x="30" y="70" width="10" height="10" fill="#a9a9a9" />
                <rect x="60" y="70" width="10" height="10" fill="#a9a9a9" />
                <line
                  x1="30"
                  y1="70"
                  x2="40"
                  y2="80"
                  stroke="#000"
                  stroke-width="2"
                />
                <line
                  x1="70"
                  y1="70"
                  x2="60"
                  y2="80"
                  stroke="#000"
                  stroke-width="2"
                />

                {/* <!-- Plants --> */}
                <circle cx="15" cy="15" r="8" fill="#42c27d" />
                <circle cx="85" cy="15" r="8" fill="#42c27d" />
              </svg>
            }
          />
          <ServiceCard
            title="Interior Finishing"
            details="Our interior finishing solutions add the perfect finishing touches to your space. With meticulous craftsmanship and attention to detail, we elevate aesthetics and functionality in every project."
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 100 100"
              >
                {/* <!-- Wall --> */}
                <rect x="10" y="20" width="80" height="60" fill="#ffffff" />

                {/* <!-- Paint Roller --> */}
                <circle cx="25" cy="80" r="15" fill="#f0c418" />
                <path d="M25 80 L25 40" stroke="#222" stroke-width="3" />

                {/* <!-- Furniture --> */}
                <rect x="65" y="55" width="25" height="20" fill="#a9a9a9" />
                <rect x="70" y="60" width="5" height="10" fill="#000" />
                <rect x="85" y="60" width="5" height="10" fill="#000" />
              </svg>
            }
          />
          <ServiceCard
            title="Decors"
            details="Our decor offerings provide the final flourish to your space, infusing it with personality and charm. Discover unique pieces that turn any environment into a work of art."
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 100 100"
              >
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  stroke="#ffffff"
                  stroke-width="5"
                  fill="none"
                />
              </svg>
            }
          />
          <ServiceCard
            title="Granite, Marble, Ceramics, and Interlocks"
            details="Explore our range of high-quality granite, marble, ceramics, and interlocks for your interior and exterior needs. Elevate your space with the beauty and durability of these premium materials."
            icon={
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.2625 10.6312C27.1688 7.36875 22.8375 5.34375 18 5.34375C8.60626 5.34375 1.01251 12.9937 1.01251 22.3875C1.01251 25.0312 1.63126 27.6187 2.75626 29.925C2.92501 30.2625 3.20626 30.4875 3.54376 30.6C3.65626 30.6 3.71251 30.6562 3.82501 30.6562C3.82501 30.6562 3.82501 30.6562 3.88126 30.6562C3.88126 30.6562 3.88126 30.6562 3.93751 30.6562C3.99376 30.6562 4.05001 30.6562 4.10626 30.6562C4.21876 30.6562 4.38751 30.6 4.50001 30.5437L6.80626 29.4187C7.42501 29.1375 7.70626 28.35 7.36876 27.7312C7.03126 27.1125 6.30001 26.8312 5.68126 27.1687L4.55626 27.7312C3.88126 26.1 3.60001 24.3562 3.54376 22.5562H5.90626C6.58126 22.5562 7.20001 21.9937 7.20001 21.2625C7.20001 20.5312 6.63751 19.9687 5.90626 19.9687H3.71251C4.10626 17.4937 5.17501 15.2437 6.69376 13.3875L8.71876 15.4125C8.94376 15.6375 9.28126 15.8062 9.61876 15.8062C9.95626 15.8062 10.2938 15.6937 10.5188 15.4125C11.025 14.9062 11.025 14.1187 10.5188 13.6125L8.43751 11.5312C10.6875 9.5625 13.5563 8.2125 16.7625 7.9875V11.4187C16.7625 12.0937 17.325 12.7125 18.0563 12.7125C18.7313 12.7125 19.35 12.15 19.35 11.4187V7.9875C22.5 8.26875 25.425 9.5625 27.675 11.5312L26.1 13.1062C25.5938 13.6125 25.5938 14.4 26.1 14.9062C26.325 15.1312 26.6625 15.3 27 15.3C27.3375 15.3 27.675 15.1875 27.9 14.9062L29.4188 13.3875C30.9375 15.2437 31.95 17.4937 32.4 19.9687H30.2063C29.5313 19.9687 28.9125 20.5312 28.9125 21.2625C28.9125 21.9937 29.475 22.5562 30.2063 22.5562H32.5688C32.5688 24.3562 32.2313 26.1 31.5563 27.7875L30.4313 27.225C29.8125 26.8875 29.025 27.1687 28.7438 27.7875C28.4625 28.4062 28.6875 29.1937 29.3063 29.475L31.6125 30.6C31.7813 30.7125 32.0063 30.7125 32.175 30.7125C32.175 30.7125 32.175 30.7125 32.2313 30.7125C32.2313 30.7125 32.2313 30.7125 32.2875 30.7125C32.7375 30.7125 33.1875 30.4312 33.4125 30.0375C34.5938 27.7312 35.1563 25.0875 35.1563 22.5C35.0438 17.8312 33.1875 13.6687 30.2625 10.6312Z"
                  fill="white"
                />
                <path
                  d="M21.4313 19.3499L17.6625 22.1624C16.9875 22.2749 16.3688 22.6687 15.975 23.2312C15.9188 23.3437 15.8625 23.3999 15.8063 23.5124L15.6938 23.6249H15.75C15.1313 24.8062 15.4688 26.2687 16.5938 27.1124C17.7188 27.8999 19.2375 27.7874 20.1375 26.8312H20.1938L20.25 26.7187C20.3063 26.6624 20.4188 26.5499 20.475 26.4374C20.925 25.8749 21.0375 25.1437 20.9813 24.4687L22.4438 19.9687C22.6125 19.4624 21.9375 19.0124 21.4313 19.3499Z"
                  fill="white"
                />
              </svg>
            }
          />
          <ServiceCard
            title="Paints"
            details="Our selection of paints offers a spectrum of colors to bring life to your walls and spaces. From vivid hues to subtle tones, we have the perfect paint for every project."
            icon={
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <path
                  d="M27.995 5.338v-1.654c0-1.442-1.169-2.611-2.611-2.611h-17.167c-1.442 0-2.61 1.169-2.61 2.611v1.654h-0.489c-1.204 0-2.178 0.976-2.178 2.179v3.649c0 1.203 0.975 2.179 2.178 2.179l10.037-0.003c0.613 0 1.113 0.499 1.113 1.113v3.679h-2.131v12.795h5.331v-12.795h-2.134v-3.679c0-1.203-0.976-2.179-2.179-2.179h-1.32v0.003h-8.716c-0.613 0-1.112-0.499-1.112-1.113v-3.649c0-0.613 0.499-1.113 1.112-1.113h0.489v1.654c0 1.442 1.168 2.611 2.61 2.611h17.167c1.442 0 2.611-1.169 2.611-2.611v-1.654h1.066v-1.066h-1.066zM18.4 29.861h-3.199v-10.663h3.199v10.663zM26.929 8.058c0 0.852-0.694 1.545-1.545 1.545h-17.167c-0.852 0-1.544-0.693-1.544-1.545v-4.374c0-0.852 0.692-1.545 1.544-1.545h17.167c0.852 0 1.545 0.693 1.545 1.545v4.374z"
                  fill="#ffffff"
                ></path>
              </svg>
            }
          />
          <ServiceCard
            title="Electricity"
            details="Our electrical services ensure your space is well-lit, safe, and efficiently powered. From installations to repairs, we provide expert solutions for your electrical needs."
            icon={
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0" />

                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />

                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M15.59 5H15.25V2C15.25 1.59 14.91 1.25 14.5 1.25C14.09 1.25 13.75 1.59 13.75 2V5H10.25V2C10.25 1.59 9.91 1.25 9.5 1.25C9.09 1.25 8.75 1.59 8.75 2V5H8.41C7.36 5 6.5 5.86 6.5 6.91V12C6.5 14.2 8 16 10.5 16H11.25V22C11.25 22.41 11.59 22.75 12 22.75C12.41 22.75 12.75 22.41 12.75 22V16H13.5C16 16 17.5 14.2 17.5 12V6.91C17.5 5.86 16.64 5 15.59 5Z"
                    fill="#ffffff"
                  />{" "}
                </g>
              </svg>
            }
          />
        </div>
      </div>
    </section>
  );
};

export default Services;

const ServiceCard = ({ icon, title, details }) => {
  return (
    <>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div className="mb-9 rounded-[20px] bg-white p-10 shadow-2 hover:shadow-lg  md:px-7 xl:px-10">
          {/* <div className="mb-8 flex h-[80px] w-[80px] items-center justify-center rounded-2xl bg-black">
            {icon}
          </div> */}
          <h4 className="mb-[14px] text-2xl font-semibold text-dark ">
            {title}
          </h4>
          <p className="text-body-color ">{details}</p>
        </div>
      </div>
    </>
  );
};
