import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <nav className="relative px-4 py-4 flex justify-between items-center bg-white">
      <a className="text-3xl font-bold leading-none" href="/">
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="100.000000pt"
          height="35.000000pt"
          viewBox="0 0 1145.000000 735.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,735.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
          >
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M3050 3600 l0 -1060 215 0 215 0 0 71 0 72 49 -46 c60 -56 149 -102
            238 -123 89 -21 243 -14 334 15 129 41 257 146 332 272 l37 64 0 -163 0 -162
            220 0 220 0 0 173 0 172 32 -55 c39 -68 146 -174 221 -219 208 -123 520 -147
            764 -59 197 71 350 223 413 408 31 92 38 251 16 354 -53 237 -253 431 -516
            500 -86 23 -275 31 -369 17 -222 -35 -437 -170 -529 -331 l-32 -55 0 608 0
            607 -220 0 -220 0 0 -592 0 -593 -37 64 c-50 84 -172 200 -254 240 -126 61
            -300 78 -442 42 -87 -23 -135 -46 -201 -95 -25 -20 -48 -36 -51 -36 -3 0 -5
            218 -5 485 l0 485 -215 0 -215 0 0 -1060z m857 -165 c102 -51 156 -141 156
            -266 0 -126 -60 -226 -163 -273 -70 -32 -198 -29 -266 6 -99 52 -156 145 -156
            257 -1 84 14 137 52 189 82 114 245 151 377 87z m1838 0 c62 -30 109 -79 138
            -144 30 -66 29 -178 -2 -248 -26 -58 -93 -126 -149 -151 -51 -22 -194 -21
            -245 2 -52 23 -114 81 -139 130 -27 52 -36 177 -18 242 47 167 250 250 415
            169z"
            />
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M6256 3788 c5 -7 104 -138 221 -291 118 -152 216 -284 219 -291 3 -9
            -92 -134 -242 -318 -136 -167 -256 -313 -267 -325 l-20 -23 265 0 266 0 137
            176 c75 96 140 173 143 169 4 -3 66 -81 137 -173 l131 -167 269 -3 268 -2 -19
            22 -19 23 25 -21 c54 -47 91 -59 180 -59 98 0 140 17 202 82 74 77 98 190 62
            296 -18 52 -83 125 -135 153 -50 26 -177 31 -234 10 -54 -21 -123 -86 -150
            -141 -29 -61 -32 -170 -6 -231 11 -27 15 -42 8 -33 -6 8 -111 136 -232 284
            -121 148 -219 272 -217 277 1 6 294 391 445 586 7 9 -43 12 -247 12 l-256 0
            -102 -137 c-56 -76 -104 -141 -107 -145 -4 -4 -55 57 -115 137 l-108 145 -255
            0 c-201 0 -253 -3 -247 -12z"
            />
          </g>
        </svg>
      </a>
      <div className="relative">
        <div className="lg:hidden">
          <button
            className="navbar-burger flex items-center text-black p-3"
            onClick={toggleDrawer}
          >
            <svg
              className="block h-4 w-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Mobile menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
        <div
          className={`${
            isDrawerOpen ? "translate-x-0" : "translate-x-full"
          } transform top-0 right-0 w-64 h-full bg-white fixed lg:hidden transition-transform ease-in-out duration-300`}
        >
          <button
            className="absolute top-3 left-3 text-gray-600"
            onClick={toggleDrawer}
          >
            <svg
              className="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M20.293 4.293a1 1 0 00-1.414-1.414L12 10.586 5.121 3.707a1 1 0 00-1.414 1.414L10.586 12l-6.889 6.879a1 1 0 101.414 1.414L12 13.414l6.879 6.889a1 1 0 001.414-1.414L13.414 12l6.879-6.889z"
              />
            </svg>
          </button>
          <ul className="mt-12">
            <li>
              <a
                href="/"
                className="block px-4 py-2 text-gray-600 hover:text-blue-600"
                onClick={toggleDrawer}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/services"
                className="block px-4 py-2 text-gray-600 hover:text-blue-600"
                onClick={toggleDrawer}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="/about"
                className="block px-4 py-2 text-gray-600 hover:text-blue-600"
                onClick={toggleDrawer}
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className="block px-4 py-2 text-gray-600 hover:text-blue-600"
                onClick={toggleDrawer}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
      <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">
        <li>
          <NavLink
            className="text-sm text-gray-400 hover:text-gray-500 focus:text-black focus:font-bold"
            to="/"
          >
            Home
          </NavLink>
        </li>

        <li className="text-gray-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            className="w-4 h-4 current-fill"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
          </svg>
        </li>
        <li>
          <NavLink
            className="text-sm text-gray-400 hover:text-gray-500 focus:text-black focus:font-bold"
            to="/services"
          >
            Services
          </NavLink>
        </li>
        <li className="text-gray-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            className="w-4 h-4 current-fill"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
          </svg>
        </li>
        <li>
          <NavLink
            className="text-sm text-gray-400 focus:text-black focus:font-bold"
            to="/about"
          >
            About Us
          </NavLink>
        </li>

        <li className="text-gray-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            className="w-4 h-4 current-fill"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
          </svg>
        </li>
        <li>
          <NavLink
            className="text-sm text-gray-400 hover:text-gray-500 focus:text-black focus:font-bold"
            to="/contact"
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
