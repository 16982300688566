import React from "react";
import TextTruncate from "react-text-truncate";
// const image = require("../images/interior.jpg");

const HomePage = () => {
  return (
    <div>
      <img
        className="w-full h-auto"
        src="https://i.postimg.cc/tCXhb31R/interior.jpg"
        alt=""
      />
      <section className="bg-white  antialiased">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold leading-tight tracking-tight text-gray-900 sm:text-4xl ">
              Our work
            </h2>
            <p className="mt-4 text-base font-normal text-gray-500 sm:text-xl ">
              Crafted with skill and care to help our clients grow their
              business!
            </p>
          </div>

          <div className="grid grid-cols-1 mt-12 text-center sm:mt-16 gap-x-20 gap-y-12 sm:grid-cols-2 lg:grid-cols-3">
            <div className="space-y-4">
              <span className="bg-gray-100 text-gray-900 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded  ">
                Residential
              </span>
              <h3 className="text-2xl font-bold leading-tight text-gray-900 ">
                Residential
              </h3>
              <TextTruncate
                line={100}
                element="p"
                truncateText="…"
                className="text-lg font-normal text-gray-500 "
                text="In our residential finishing construction projects, we turn houses into homes, where every detail reflects your unique style and preferences. Our team of skilled professionals specializes in crafting exquisite interiors, from beautifully textured walls to flawlessly installed fixtures. We take pride in delivering projects on time and within budget, ensuring your dream home becomes a reality. With us, your residential space will exude warmth, comfort, and sophistication."
                // textTruncateChild={<a href="!#">Read on</a>}
              />
              {/* <a
                href="!#"
                title=""
                className="text-white bg-black justify-center hover:bg-primary-800 inline-flex items-center  focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                role="button"
              >
                View case study
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 ml-2 -mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </a> */}
            </div>

            <div className="space-y-4">
              <span className="bg-gray-100 text-gray-900 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded ">
                Commercial
              </span>
              <h3 className="text-2xl font-bold leading-tight text-gray-900 ">
                Commercial
              </h3>
              <TextTruncate
                line={100}
                element="p"
                truncateText="…"
                className="text-lg font-normal text-gray-500 "
                text="In our commercial finishing construction endeavors, we shape professional environments that inspire success. From sleek office interiors to dynamic retail spaces, we excel in creating functional and aesthetically pleasing commercial areas. Our experienced team is dedicated to meeting your specific business needs, ensuring that your workspace reflects your brand's identity and fosters productivity. With our commercial finishing expertise, we build the foundation for your business to thrive."
                // textTruncateChild={<a href="!#">Read on</a>}
              />

              {/* <a
                href="!#"
                title=""
                className="text-white bg-black justify-center hover:bg-primary-800 inline-flex items-center  focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                role="button"
              >
                View case study
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 ml-2 -mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </a> */}
            </div>
            <div className="space-y-4">
              <span className="bg-gray-100 text-gray-900 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded ">
                Agricultural
              </span>
              <h3 className="text-2xl font-bold leading-tight text-gray-900 ">
                Agricultural
              </h3>
              <TextTruncate
                line={100}
                element="p"
                truncateText="…"
                className="text-lg font-normal text-gray-500 "
                text="In the realm of agricultural finishing construction, we specialize in designing and building functional yet aesthetically pleasing structures that enhance productivity. Our projects combine sturdy materials with efficient layouts, ensuring that every aspect of your agricultural facility is optimized. From barns to storage units, we create durable, high-performance spaces that cater to your specific farming needs. With our expertise, your agricultural operations will flourish within thoughtfully crafted environments."
                // textTruncateChild={<a href="!#">Read on</a>}
              />
              {/* <a
                href="!#"
                title=""
                className="text-white bg-black justify-center hover:bg-primary-800 inline-flex items-center  focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                role="button"
              >
                View case study
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 ml-2 -mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </a> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
